<template>
<section class="golden-pawnshop-promo">
  <div class="golden-pawnshop__wrapper">
    <div class="golden-pawnshop__container">
      <div class="golden-pawnshop-promo__container">
        <div class="golden-pawnshop-promo__title">
          <h2 class="golden-pawnshop-promo__h2">Эффективные решения</h2>
          <h2 class="golden-pawnshop-promo__h2">Лучшие предложения</h2>
        </div>
        <div class="golden-pawnshop-promo__benefits">
          <div
            class="golden-pawnshop-promo__benefits-item"
            v-for="(item, key) in benefits"
            :key="key"
          >
            <img :src="item.imgSrc" :alt="item.text">
            <p v-html="item.text"/>
          </div>
        </div>
        <button class="golden-pawnshop-promo__button" @click="scrollTo">УЗНАТЬ БОЛЬШЕ</button>
      </div>
    </div>
  </div>
</section>
</template>

<script>
export default {
  name: 'Promo',
  data() {
    return {
      benefits: [
        {
          imgSrc: '/images/money-icon.png',
          text: 'Оценка и&nbsp;наличные в&nbsp;день обращения',
        },
        {
          imgSrc: '/images/lock-icon.png',
          text: 'Полная конфиденциальность',
        },
        {
          imgSrc: '/images/dollar-icon.png',
          text: 'Оцениваем дороже конкурентов',
        },
      ],
    };
  },
  methods: {
    scrollTo() {
      const el = document.querySelector('.golden-pawnshop-services');

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>
