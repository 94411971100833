<template>
  <a href="" class="golden-pawnshop-main-logo">
    <img src="images/logo.png" alt="Золотой ломбард"
         class="golden-pawnshop-main-logo__logo">
    <div class="golden-pawnshop-main-logo__title">
      <h1>ЗОЛОТОЙ ЛОМБАРД</h1>
      <p>КРАСНОДАР</p>
    </div>
  </a>
</template>

<script>
export default {
  name: 'MainLogo',
};
</script>
