<template>
  <div id="app" class="golden-pawnshop">
    <Header/>
    <Promo/>
    <Services/>
    <About/>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Promo from '@/components/Promo.vue';
import Services from '@/components/Services.vue';
import About from '@/components/About.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'App',
  components: {
    Header,
    Promo,
    Services,
    About,
    Footer,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Regular.ttf');
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat-Medium.ttf');
  src: url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@import "./assets/scss/default";
</style>
