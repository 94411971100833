<template>
<footer class="golden-pawnshop-footer">
  <div class="golden-pawnshop__wrapper">
    <div class="golden-pawnshop__container">
      <div class="golden-pawnshop-footer__container">
        <div class="golden-pawnshop-footer__left">
          <MainLogo/>
          <div class="golden-pawnshop-footer__contacts">
            <Contacts/>
          </div>
        </div>
        <div class="golden-pawnshop-footer__right">
          <nav class="golden-pawnshop-footer__nav">
            <a
              v-for="(link, key) in links"
              :key="key"
              v-html="link.title"
              @click="link.scrollToID"
            />
          </nav>
        </div>
      </div>
    </div>
  </div>
</footer>
</template>

<script>
import MainLogo from '@/components/MainLogo.vue';
import Contacts from '@/components/Contacts.vue';

export default {
  name: 'Footer',
  components: {
    MainLogo,
    Contacts,
  },
  data() {
    return {
      links: [
        {
          title: 'ГЛАВНАЯ',
          scrollToID: this.scrollToPromo,
        },
        {
          title: 'УСЛУГИ',
          scrollToID: this.scrollToServices,
        },
        {
          title: 'О ЛОМБАРДЕ',
          scrollToID: this.scrollToAbout,
        },
      ],
    };
  },
  methods: {
    scrollTo(elIdentifier) {
      const el = document.querySelector(elIdentifier);

      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    },
    scrollToPromo() {
      return this.scrollTo('.golden-pawnshop-promo');
    },
    scrollToServices() {
      return this.scrollTo('.golden-pawnshop-services');
    },
    scrollToAbout() {
      return this.scrollTo('.golden-pawnshop-about');
    },
  },
};
</script>
