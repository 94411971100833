<template>
  <div class="golden-pawnshop-services">
    <div class="golden-pawnshop__wrapper">
      <div class="golden-pawnshop__container">
        <div class="golden-pawnshop-services__container">
          <div class="golden-pawnshop-services__content">
            <h2 class="golden-pawnshop__page-title">НАШИ УСЛУГИ</h2>
            <h4 v-html="subTitle"/>
            <ul>
              <li
                v-for="(benefit, key) in benefits"
                :key="key"
                v-html="benefit"
              />
            </ul>
            <p v-html="description"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services',
  data() {
    return {
      subTitle: 'ООО&nbsp;“Золотой ломбард” оказывает услуги по&nbsp;выдаче займов под&nbsp;залог ювелирных изделий из&nbsp;золота с&nbsp;процентной ставкой от&nbsp;0.1 % в&nbsp;сутки.*',
      description:
        '*&nbsp;ООО&nbsp;"Золотой ломбард" оказывает услуги в&nbsp;соответствии с&nbsp;требованиями Федеральных законов: №&nbsp;196-ФЗ от&nbsp;19.07.2007 "О&nbsp;ломбардах" и&nbsp;№&nbsp;353-ФЗ от&nbsp;21.12.2013 "О&nbsp;потребительском кредите (займе)".<br>Вышеуказанное предложение не&nbsp;является офертой.<br>Тарифы на&nbsp;оказываемые услуги и&nbsp;процентные ставки утверждены в&nbsp;соответствии с&nbsp;приказами директора ООО&nbsp;"Золотой ломбард".',
      benefits: [
        'низкая процентная ставка по&nbsp;займам',
        'страхование залогового имущества',
        'высокая оценочная стоимость',
        'бесплатная экспертиза',
        'полная конфиденциальность сделки',
      ],
    };
  },
};
</script>
