<template>
<div class="golden-pawnshop-contacts">
  <a
    href="tel:+79000637672"
    class="golden-pawnshop-contacts__tel"
  >
    +7&nbsp;900&nbsp;063-76-72
  </a>
  <a
    href="mailto:zolotoylombardi@mail.ru"
    class="golden-pawnshop-contacts__email"
  >
    zolotoylombardi@mail.ru
  </a>
</div>
</template>

<script>
export default {
  name: 'Contacts',
};
</script>

<style scoped>

</style>
