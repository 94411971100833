<template>
  <section class="golden-pawnshop-about">
    <div class="golden-pawnshop__wrapper">
      <div class="golden-pawnshop__container">
        <div class="golden-pawnshop-about__container">
          <div class="golden-pawnshop-about__content">
            <div class="golden-pawnshop-about__main-info">
              <div class="golden-pawnshop-about__main-info-container">
                <h2 class="golden-pawnshop__page-title" v-html="title"/>
                <p class="golden-pawnshop-about__main-info-text" v-html="mainText"/>
                <p class="golden-pawnshop-about__main-info-promo" v-html="promoText"/>
              </div>
            </div>
            <div class="golden-pawnshop-about__map">
              <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ad217f861ae590db301ffb4e7545aa9ffabd8e40aba09861d546c49f4596f817a&amp;source=constructor" width="600" height="500" frameborder="0"></iframe>
            </div>
            <div class="golden-pawnshop-about__requisites">
              <div class="golden-pawnshop-about__requisites-container">
                <h4 class="golden-pawnshop-about__requisites-title" v-html="requisites.title"/>
                <ul class="golden-pawnshop-about__requisites-list">
                  <li
                    v-for="(item, key) in requisites.items"
                    :key="key"
                    v-html="item"
                  />
                </ul>
                <h4 class="golden-pawnshop-about__requisites-title" v-html="documents.title"/>
                <ul class="golden-pawnshop-about__requisites-list">
                  <li
                    v-for="(item, key) in documents.links"
                    :key="key"
                  >
                    <a :href="item.href" v-html="item.title" target="_blank"/>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
  data() {
    return {
      title: 'О&nbsp;ЛОМБАРДЕ',
      mainText: 'ООО&nbsp;“Золотой ломбард” оказывает услуги на&nbsp;основании свидетельства о&nbsp;регистрации юридического лица №&nbsp;00&nbsp;ХХХХХХХХ от&nbsp;ХХ.ХХ.ХХХХ&nbsp;г., в&nbsp;соответствии с&nbsp;Федеральным законом №&nbsp;196-ФЗ от&nbsp;19.07.2007 "О&nbsp;ломбардах" и&nbsp;Федеральным законом №&nbsp;353-ФЗ от&nbsp;21.12.2013 "О&nbsp;потребительском кредите (займе)"',
      promoText: 'быстро — выгодно — профессионально',
      requisites: {
        title: 'Реквизиты ООО&nbsp;“Золотой ломбард”',
        items: [
          'ИНН:&nbsp;2311315938',
          'ОГРН:&nbsp;1212300008051',
          'Адрес: Республика Адыгея, Тахтамукайский район, п.г.т.&nbsp;Энем, ул.&nbsp;Седина, д.&nbsp;47',
          'Контактный телефон: +7&nbsp;900&nbsp;063-76-72',
          'E-mail: zolotoylombardi@mail.ru',
        ],
      },
      documents: {
        title: 'Документы ООО&nbsp;“Золотой ломбард”',
        links: [
          {
            href: 'files/svidetelstvo.pdf',
            title: 'Свидетельство о&nbsp;регистрации&nbsp;ЮЛ',
          },
          {
            href: 'files/loan_terms.pdf',
            title: 'Общие условия договора потребительского займа',
          },
          {
            href: 'files/legal_lombards.pdf',
            title: 'Федеральный закон №&nbsp;196-ФЗ от&nbsp;19.07.2007 "О&nbsp;ломбардах"',
          },
          {
            href: 'files/legal_loans.pdf',
            title: 'Федеральный закон №&nbsp;353-ФЗ от&nbsp;21.12.2013 "О&nbsp;потребительском кредите (займе)"',
          },
        ],
      },
    };
  },
};
</script>

<style scoped>

</style>
