<template>
  <header class="golden-pawnshop-header">
    <div class="golden-pawnshop__wrapper">
      <div class="golden-pawnshop__container">
        <div class="golden-pawnshop-header__container">
          <MainLogo/>
          <div class="golden-pawnshop-header__right">
            <Contacts/>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import MainLogo from '@/components/MainLogo.vue';
import Contacts from '@/components/Contacts.vue';

export default {
  name: 'Header',
  components: {
    MainLogo,
    Contacts,
  },
};
</script>
